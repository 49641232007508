import React, { useState, useRef, useEffect } from "react";

import { BsPrinterFill } from "react-icons/bs";
import {
  IoIosNotifications,
  IoIosArrowUp,
  IoIosArrowDown,
} from "react-icons/io";

import styles from "./Tracking.module.css";
import AnimatedRoute from "./AnimatedRoute/AnimatedRoute";

import axios from "axios";

const Tracking = () => {
  const [orderId, setOrderId] = useState("");
  const [validTracking, setValidTracking] = useState(false); // [false, setValidTracking
  const [shipmentUpdate, setShowShipmentUpdate] = useState(true);
  // const shipmentInfo = [
  //   {
  //     day: "Friday",
  //     date: "June, 30 2023",
  //     time: "13:35",
  //     ServiceArea: 3282332322494,
  //   },
  //   {
  //     day: "Friday",
  //     date: "June, 30 2023",
  //     time: "13:35",
  //     ServiceArea: 3282332322494,
  //   },
  //   {
  //     day: "Friday",
  //     date: "June, 30 2023",
  //     time: "13:35",
  //     ServiceArea: 3282332322494,
  //   },
  // ];

  const [shipmentInfo, setShipmentInfo] = useState();
  const trackDetails = shipmentInfo;

  const getShipments = async (e) => {
    e.preventDefault();

    let api = "https://courier-backend-service.bigrouting.io";
    let route = "/tracking" + "/" + orderId;
    try {
      const { data, status } = await axios.get(
        api + route,
        {
          headers: {
            "Content-Type": "application/json",
            "x-tenant-id": "starli_979a3f"
          },
        }
      );
      console.log(data);
      setShipmentInfo(data.shipment);
      setValidTracking(true);
    } catch (e) {
      setValidTracking(false);
      // if (status === 404) {
      //   alert("Invalid Tracking Number")
      // }
      if (e.response.status === 404) {
        alert("Invalid Tracking Number");
      }
    }
  };
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);
  return (
    <>
      <div className={styles.mainContainer}>
        {" "}
        <h2 className={styles.heading}>Track Shipment</h2>
        <div className={styles.mainWrapper}>
          <form
            action=""
            onSubmit={(e) => e.target.default()}
            className={styles.inputContainer}
          >
            <div className={styles.inputWrapper}>
              <label htmlFor="trackingNumber" className={styles.label}>
                Enter your tracking number(s)
              </label>
              <input
                ref={inputRef}
                style={{ border: "none" }}
                type="text"
                name="trackingNumber"
                id="trackingNumber"
                className={styles.input}
                value={orderId}
                onChange={(e) => {
                  setOrderId(e.target.value);
                }}
              />
            </div>
            <button onClick={getShipments} className={styles.button}>
              Track
            </button>
          </form>
          {validTracking && (
            <div className={styles.wrapper}>
              <div className={styles.header}>
                <div className={styles.textWrapper}>
                  <p className={styles.trackingNumber}>
                    Tracking Code: {trackDetails?.tracking_id}
                  </p>
                  <p className={styles.trackingNumber}>
                    TYPE OF Shipment : {trackDetails?.type_of_shipment ? trackDetails?.type_of_shipment : "N/A"}
                  </p>
                  <p className={styles.trackingNumber}>
                    WEIGHT OF PACKAGE : {trackDetails?.weight_of_shipment ? trackDetails?.weight_of_shipment : "N/A"}
                  </p>
                  <p className={styles.trackingNumber}>
                    TYPE OF PACKAGE: {trackDetails?.type_of_package ? trackDetails?.type_of_package : "N/A"}
                  </p>
                  <p className={styles.trackingNumber}>
                    Date and time received : {trackDetails?.date_and_time_recieved ? trackDetails?.date_and_time_recieved : "N/A"}
                  </p>
                  <p className={styles.trackingNumber}>
                    Estimated delivery date : {trackDetails?.estimate_delivery_date ? trackDetails?.estimate_delivery_date : "N/A"}
                  </p>
                  <p className={styles.shipmentBy}>
                    This shipment is handled by: {trackDetails?.cargo_handler}
                    <span className={styles.highlight}>
                      {trackDetails?.shipmentHandler}
                    </span>
                  </p>
                </div>{" "}
                <button className={styles.printButton}>
                  <p className={styles.printText}>Print</p>
                  <BsPrinterFill className={styles.printText} />
                </button>
              </div>

              <div
                className={[styles.shipmentInfo, styles.textWrapper].join(" ")}
              >
                <h3 className={styles.title}>Shipment information received</h3>
                <p className={styles.text}>
                  {new Date(trackDetails?.date_and_time_recieved).toLocaleString()} Local Time
                </p>{" "}
                <div className={styles.routeContainer}>
                  <p className={[styles.routeText, styles.leftRoute].join(" ")}>
                    Service Area: {trackDetails?.from}
                  </p>
                  <p className={[styles.routeText, styles.leftRoute].join(" ")}>
                    Sender: {trackDetails?.sender}
                  </p>
                  <AnimatedRoute />
                  <p
                    className={[styles.routeText, styles.rightRoute].join(" ")}
                  >
                    Service Area: {trackDetails?.to}
                  </p>
                  <p
                    className={[styles.routeText, styles.rightRoute].join(" ")}
                  >
                    Reciever: {trackDetails?.receiver}
                  </p>
                </div>
              </div>

              <div
                className={[
                  styles.shipmentNotification,
                  styles.textWrapper,
                ].join(" ")}
              >
                <p className={styles.text}>
                  To protect your privacy, more delivery details are available
                  after validation.
                </p>
                <button className={styles.notificationButton}>
                  <p className={styles.notificationText}>
                    Shipment Notifications{" "}
                  </p>
                  <IoIosNotifications className={styles.notificationIcon} />
                </button>
              </div>
              <div className={styles.shipmentUpdate}>
                <div className={styles.spaceBetween}>
                  <h3 className={styles.title}>All Shipment Updates</h3>
                  {shipmentUpdate ? (
                    <IoIosArrowUp
                      className={styles.arrow}
                      onClick={() => setShowShipmentUpdate((prev) => !prev)}
                    />
                  ) : (
                    <IoIosArrowDown
                      className={styles.arrow}
                      onClick={() => setShowShipmentUpdate((prev) => !prev)}
                    />
                  )}
                </div>

                {shipmentUpdate &&
                  shipmentInfo?.updates?.map((el, i) => (
                    <div className={styles.grid} key={i}>
                      <div>
                        <p className={styles.text}>
                          {new Date(el?.time).toLocaleString()}
                        </p>

                        <p className={styles.text}> Local time</p>
                      </div>
                      <div className={styles.locationContainer}>
                        <img
                          src="https://cdn-icons-png.flaticon.com/128/2821/2821834.png"
                          alt="#"
                          className={styles.locationImg}
                        />
                      </div>
                      <div>
                        <p className={styles.shipmentInfoReceived}>
                          {el?.new_location}
                        </p>
                        <p className={styles.text}>Status: {el?.status}</p>
                        <p className={styles.text}>
                          Remark: {el.customs_remarks}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Tracking;
